:root {
  --dynamic-width: 350px;
  --dynamic-text-align: left;
  --dynamic-justify-content: flex-start; /* Default value */
}

/* display: flex; */

body {
  font-family: "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4A4E69;
  justify-content: var(--dynamic-justify-content);
}

form {
  display: flex;
  flex-direction: column;
  width: var(--dynamic-width);
  text-align: var(--dynamic-text-align);
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
}

form > label {
  font-size: 1.2em;
  font-weight: lighter;
  margin-top: 0.5em;
  color: #242479;
  letter-spacing: 2px;
  text-align: left;
}

form > h1 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.6em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #0f0f0f;
  letter-spacing: 2px;
}

form > h3 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.0em;
  font-weight: darker;
  margin-top: 0.25em;
  color: #0f0f0f;
  letter-spacing: 2px;
}

form > h3 > label {
  display: inline-flex;
  font-size: 0.7em;
  color: #b90b0b;
}

form > div {
  margin: 0.5em 0;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

label {
  color: #000000;
  font-weight: lighter;
}

input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.invalid {
  border: 1px solid #db2269;
  box-shadow: none;
}
input[type="checkbox"].invalid {
  box-shadow: 0px 0px 0px 1px #db2269;
}
input, select {
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #d6d1d5;
  margin-top: 5px;
  display: block;
}
select {
  width: 100%;
  height: 35px;
}
input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-top: 2em;
  margin-right: 10px;
}

button {
  min-width: 100%;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 	0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223B;
  color: #fefefe;
}
button:hover {
  background-color: #313238;
  color: #fefefe;
}
button:disabled {
  opacity: 0.2;
}

.required {
  color:#db2269;
  font-size: 0.8em;
  margin-top: 0.5em;
}
